import { MoneyOff, QrCode } from '@mui/icons-material'
import { Box, Checkbox, Link, Tooltip } from '@mui/material'
import {
  IDialogType,
  IconButton,
  Image,
  PDFGenerator,
  TableCell,
  TableRow,
} from 'components'
import {
  IDevice,
  ITransaction,
  TransactionStatus,
  TransactionType,
  IUser,
  DeviceType,
} from 'models'
import { ServicePDF } from 'templates'
import ChargeDialog from './ChargeDialog'
import { useMemo, useState } from 'react'
import DropoffDialog from './DropoffDialog'
import PickUpDialog from './PickUpDialog'
import RefundDialog from './RefundDialog'
import EndDialog from './EndDialog'
import { transactionsApi } from 'resources'
import moment from 'moment'
import {
  capitalizeFirstLetter,
  getUserRole,
  parseCurrency,
  parseHexToRGB,
} from 'utils/helpers'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import CloseIcon from '@mui/icons-material/Close'
import {
  EXPIRED_TRANSACTION_COLOR,
  ON_GOING_TRANSACTION_COLOR,
} from 'pages/Events/constants'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import PenaltyDialog from './PenaltyDialog'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'

interface ITransactionRowProps {
  transaction: ITransaction
  setCurrentUser: (user: IUser) => void
  devices: IDevice[]
  setCurrentDevice: (device: IDevice) => void
  setCurrentTransaction: (transaction: ITransaction) => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  fetchTransactions: () => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    transaction: ITransaction,
  ) => void
  selected: boolean
}

const TransactionRow = ({
  transaction,
  setCurrentUser,
  devices,
  setCurrentDevice,
  setCurrentTransaction,
  filteredColumns,
  displayMessage,
  fetchTransactions,
  handleSelectRow,
  selected,
}: ITransactionRowProps) => {
  const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false)
  const [openPickupDialog, setOpenPickupDialog] = useState<boolean>(false)
  const [openChargeDialog, setOpenChargeDialog] = useState<boolean>(false)
  const [openDropoffDialog, setOpenDropoffDialog] = useState<boolean>(false)
  const [openEndDialog, setOpenEndDialog] = useState<boolean>(false)
  const [openPenaltyDialog, setOpenPenaltyDialog] = useState<boolean>(false)

  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  const { sendCode } = transactionsApi()
  const role = getUserRole()

  const ojmarDialogMsj = useMemo(
    () =>
      'If the transaction is finished remotely, the old reservation user will ' +
      'still be available to use the locker via Keypad. To return to the default ' +
      'working mode please finish the transaction properly with ' +
      'a Smartphone and Spherio app.',
    [],
  )

  const handleSendCode = async () => {
    try {
      const response = await sendCode(
        transaction.id,
        transaction.user.phone_number,
        transaction.user.email,
      )
      displayMessage(response.detail, 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  if (orgSettings?.default_country === 'US') {
    moment.updateLocale('en', {
      meridiem: (hours) => {
        if (hours > 11) {
          return 'p'
        } else {
          return 'a'
        }
      },
    })
  }

  const getRowBackground = () => {
    if (
      ![
        TransactionStatus.finished,
        TransactionStatus.canceled,
        TransactionStatus.refunded,
        TransactionStatus.expired,
      ].includes(TransactionStatus[transaction.event_status])
    ) {
      return parseHexToRGB(ON_GOING_TRANSACTION_COLOR, '0.1')
    } else if (
      TransactionStatus[transaction.event_status] === TransactionStatus.expired
    ) {
      return parseHexToRGB(EXPIRED_TRANSACTION_COLOR, '0.1')
    } else {
      return undefined
    }
  }

  return (
    <>
      <TableRow
        sx={{
          background: getRowBackground(),
        }}
      >
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, transaction)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'invoice_id' && c.active,
        ) !== -1 && (
          <TableCell>
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCurrentTransaction(transaction)
              }}
            >
              {transaction?.invoice_id}
            </Link>
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'order_id' && c.active,
        ) !== -1 && (
          <TableCell>
            <Link
              sx={{
                cursor: 'pointer',
              }}
            >
              {transaction?.order_id}
            </Link>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_user_pickup &&
                  transaction.event_type === TransactionType.delivery && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="resend code"
                      onClick={handleSendCode}
                    >
                      <Tooltip title="Resend pin code">
                        <ForwardToInboxIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.in_progress &&
                  TransactionType[transaction.event_type] ===
                    TransactionType.service && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="charge"
                      onClick={() => setOpenChargeDialog(true)}
                    >
                      <Tooltip title="Charge user">
                        <AddCardOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_service_pickup &&
                  TransactionType[transaction.event_type] ===
                    TransactionType.service && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="pickup"
                      onClick={() => setOpenPickupDialog(true)}
                    >
                      <Tooltip title="Pickup">
                        <UnarchiveOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_service_dropoff &&
                  TransactionType[transaction.event_type] !==
                    TransactionType.delivery && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="dropoff"
                      onClick={() => setOpenDropoffDialog(true)}
                    >
                      <Tooltip title="Dropoff">
                        <ArchiveOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {[
                  TransactionStatus.finished,
                  TransactionStatus.canceled,
                  TransactionStatus.refunded,
                ].includes(TransactionStatus[transaction.event_status]) && (
                  <IconButton onClick={() => setOpenPenaltyDialog(true)}>
                    <Tooltip title="Charge Penalty">
                      <CreditCardOutlinedIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_name' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.name ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.name}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_phone' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.phone_number ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.phone_number}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_email' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.email ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.email}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'device_name' && c.active,
        ) !== -1 && (
          <TableCell>
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentDevice(transaction?.device)
                }}
              >
                {transaction?.device?.name}
              </Link>
            ) : (
              <>{transaction?.device?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'locker_number' && c.active,
        ) !== -1 && <TableCell>{transaction?.device?.locker_number}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'location' && c.active,
        ) !== -1 && (
          <TableCell>{transaction?.device?.location?.name}</TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'mode' && c.active) !==
          -1 && (
          <TableCell>
            {capitalizeFirstLetter(
              transaction.device?.mode !== 'rental'
                ? transaction.device?.mode
                : 'asset',
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'status' && c.active) !==
          -1 && (
          <TableCell>{TransactionStatus[transaction?.event_status]}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'start_date' && c.active,
        ) !== -1 && (
          <TableCell>
            {moment(transaction.created_at).format(
              orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'end_date' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction.ended_at
              ? moment(transaction.ended_at).format(
                  orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
                )
              : ''}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'duration' && c.active,
        ) !== -1 && <TableCell>{transaction?.total_time}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'refund' && c.active) !==
          -1 && (
          <TableCell>
            {TransactionStatus[transaction.event_status] ===
              TransactionStatus.finished &&
              Number(transaction?.total) > 0 && (
                <IconButton
                  onClick={() => setOpenRefundDialog(true)}
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <MoneyOff />
                </IconButton>
              )}
          </TableCell>
        )}

        {filteredColumns.findIndex((c) => c.value === 'amount' && c.active) !==
          -1 && (
          <TableCell>
            {Number(transaction?.total) > 0 &&
              parseCurrency(transaction?.device?.price?.currency)}
            {transaction?.total === 0
              ? `${parseCurrency(transaction?.device?.price?.currency)}0`
              : transaction?.total}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'end' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' && (
              <>
                {![
                  TransactionStatus.finished,
                  TransactionStatus.canceled,
                  TransactionStatus.refunded,
                ].includes(TransactionStatus[transaction.event_status]) && (
                  <IconButton
                    name="end"
                    onClick={() => {
                      if (
                        transaction.device.hardware_type == DeviceType.ojmar
                      ) {
                        displayMessage(ojmarDialogMsj, 'warning')
                      }
                      setOpenEndDialog(true)
                    }}
                  >
                    <Tooltip title="End Transaction">
                      <CloseIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell>
            <IconButton>
              <PDFGenerator
                fileName={transaction?.id}
                pageContent={<ServicePDF transaction={transaction} />}
                pageSize={'A6'}
                icon={
                  <QrCode
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? '#ffffff' : '#0000008a',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  />
                }
              />
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'image' && c.active) !==
          -1 && (
          <TableCell align="center">
            {transaction.image_url ? (
              <Image
                src={transaction.image_url}
                alt={transaction.invoice_id}
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                }}
              />
            ) : (
              <></>
            )}
          </TableCell>
        )}
      </TableRow>
      <ChargeDialog
        open={openChargeDialog}
        onClose={() => setOpenChargeDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <DropoffDialog
        open={openDropoffDialog}
        onClose={() => setOpenDropoffDialog(false)}
        transaction={transaction}
        devices={devices}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <PickUpDialog
        open={openPickupDialog}
        onClose={() => setOpenPickupDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <RefundDialog
        open={openRefundDialog}
        onClose={() => setOpenRefundDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <EndDialog
        open={openEndDialog}
        onClose={() => setOpenEndDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />

      <PenaltyDialog
        open={openPenaltyDialog}
        onClose={() => setOpenPenaltyDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
    </>
  )
}

export default TransactionRow
